import classNames from 'classnames';
import {Link} from 'gatsby';
import React, {useContext, useState} from 'react';
import {Button} from 'src/components/ui/button';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from 'src/context/globalContextProvider';
import {api} from 'src/shared/api';
import styles from './projectPicker.module.scss';

export const ProjectPicker = (props) => {
  const dispatch = useContext(GlobalDispatchContext);
  const state = useContext(GlobalStateContext);
  const [active, setActive] = useState(false);
  const [newProject, setNewProject] = useState('');
  const [newProjectError, setNewProjectError] = useState(null);
  const enabledProjects = Object.keys(state.projectMap).filter((projectId) => (
    !props.projectsWithEntries[projectId]
  ));
  const disabledProjects = Object.keys(props.projectsWithEntries);

  const closePicker = () => {
    setActive(false);
    resetValidation();
    setNewProject('');
  };

  const handleAddProject = (e) => {
    setActive(true);
  };

  const handleProjectClick = (projectId) => {
    props.onAddProject(projectId);
    closePicker();
  };

  const handleNewProjectChange = (e) => {
    setNewProject(e.target.value);
  };

  const resetValidation = () => {
    setNewProjectError(null);
  };

  const validateForm = () => {
    resetValidation();
    let valid = true;

    if (!newProject) {
      setNewProjectError(true);
      valid = false;
    }

    return valid;
  };

  const handleSaveClick = () => {
    if (!validateForm()) return;

    api().post(`/api/projects/`, {
      name: newProject,
    }).then((response) => {
      // props.onNewProject(response.data);
      dispatch({type: 'ADD_PROJECT', project: response.data});
      setNewProject('');
    }).catch((error) => {
      console.log(error);
      alert('Failed to save new project');
    });
  };

  const newProjectClasses = classNames(styles.newProject, {
    [styles.error]: newProjectError,
  });

  return (
    <div className={styles.projectPicker}>
      {active &&
        <div>
          <div className={styles.projectList}>
            {enabledProjects.map((projectId) => (
              <button
                key={projectId}
                onClick={() => handleProjectClick(projectId)}
              >
                {state.projectMap[projectId].name}
              </button>
            ))}
            {disabledProjects.map((projectId) => (
              <button
                disabled
                className={styles.disabled}
                key={projectId}
                onClick={() => handleProjectClick(projectId)}
              >
                {state.projectMap[projectId].name}
              </button>
            ))}
          </div>
          <div className={newProjectClasses}>
            <input
              type="text"
              value={newProject}
              onChange={handleNewProjectChange}
              placeholder="Enter a new project..."
            />
            <Button
              small
              className={styles.saveNewProject}
              onClick={handleSaveClick}
            >
              Save
            </Button>
          </div>
          <div className={styles.cancel}>
            <button
              onClick={closePicker}>
              Cancel
            </button>
          </div>
        </div>
      }
      {!active &&
        <div className={styles.addProject}>
          {!props.filterActive &&
            <button onClick={handleAddProject}>
              Add project
            </button>
          }
          {props.filterActive &&
            <div className={styles.disabled}>
              Adding projects is disabled while a project filter is active
            </div>
          }
        </div>
      }
    </div>
  );
};
