import moment from 'moment';
import React, {useContext, useState, useEffect} from 'react';
import {Day} from 'src/components/entries/day';
import {EntryActions} from 'src/components/entries/entryActions';
import {ContextBar, ContextItem} from 'src/components/layout/contextBar';
import Layout, {ContentWrapper} from 'src/components/layout/layout';
import SEO from 'src/components/seo';
import {Card} from 'src/components/ui/card';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from 'src/context/globalContextProvider';
import {api} from 'src/shared/api';
import {setSessionData} from 'src/shared/utilities/session';
import {getProjectFilter} from 'src/shared/utilities/session';
import styles from './index.module.scss';

export default () => {
  const dispatch = useContext(GlobalDispatchContext);
  const state = useContext(GlobalStateContext);
  const [loading, setLoading] = useState(true);
  const [trialExpired, setTrialExpired] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [days, setDays] = useState({});
  const [projectFilter] = useState(getProjectFilter());
  const filterActive = Object.keys(projectFilter).length > 0;

  useEffect(() => {
    api().get('/api/dashboard/').then((response) => {
      dispatch({type: 'SET_PROJECTS', projects: response.data.projects});
      dispatch({type: 'SET_ENTRIES', entries: response.data.entries});
      setTrialExpired(response.data.trial_expired);
      setIsSubscribed(response.data.is_subscribed);
      setLoading(false);
    }).catch((error) => {
      console.log(error);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    setDays(daysFromEntries(state.entries, state.projectMap));
  }, [state.entries]);

  useEffect(() => {
    // Update these session keys when they become truthy
    if (trialExpired) {
      setSessionData('trial_expired', true);
    }
    if (isSubscribed) {
      // setSessionData('is_subscribed', true);
      setSessionData('trial_expired', false);
    }
  }, [trialExpired, isSubscribed]);

  const daysFromEntries = (entries, projects) => {
    const days = {};

    (entries || []).forEach((entry) => {
      if (!filterActive || projectFilter[entry.project]) {
        days[entry.date] = days[entry.date] || {};
        days[entry.date]['entries'] = days[entry.date]['entries'] || {};
        days[entry.date]['entries'][entry.pk] = entry;
      }
    });

    // Initialize today if there aren't any entries yet
    // const today = moment().format('YYYY-MM-DD');
    // if (!days[today]) {
    // days[today] = {
    // entries: {},
    // };
    // }

    return days;
  };

  const handleNewProject = (project) => {
    alert('not migrated yet');
  };

  const handleEntryDeleted = (pk) => {
    setEntries(entries.filter((e) => e.pk !== pk));
  };

  // const handleEntrySaved = (entry) => {
  // console.log('DOES THIS EVER GET CALLED?');
  // const isUpdate = entries.some((e) => e.pk === entry.pk);
  // if (isUpdate) {
  // setEntries(entries.map((e) => {
  // return e.pk === entry.pk ? {...e, ...entry} : e;
  // }));
  // } else {
  // setEntries([
  // ...entries,
  // entry,
  // ]);
  // }
  // };

  return (
    <Layout trialExpired={trialExpired}>
      <SEO title='Journal' />
      <ContextBar>
        <ContextItem
          text="Journal"
        />
        {!loading &&
          <EntryActions
            showQuickEntry
          />
        }
      </ContextBar>
      <ContentWrapper>
        {loading &&
          <div>
            <Card skeleton />
            <Card skeleton />
            <Card skeleton />
          </div>
        }
        {!loading && filterActive &&
          <div className={styles.filterMessage}>
            These entries are filtered.
          </div>
        }
        {!loading && Object.keys(days).sort((a, b) => moment(b) - moment(a)).map((key) => (
          <Day
            key={`day-${key}`}
            date={key}
            data={days[key]}
            onNewProject={handleNewProject}
            onEntryDeleted={handleEntryDeleted}
            filterActive={filterActive}
          />
        ))}
      </ContentWrapper>
    </Layout>
  );
};
