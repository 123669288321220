import moment from 'moment';
import React, {useContext, useEffect, useState} from 'react';
import {Project} from 'src/components/entries/project';
import {ProjectPicker} from 'src/components/entries/projectPicker';
import {Card} from 'src/components/ui/card';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from 'src/context/globalContextProvider';
import {api} from 'src/shared/api';
import {browser} from 'src/shared/utilities/environment';
import {setSessionData} from 'src/shared/utilities/session';
import styles from './day.module.scss';

const TRIAL_EXPIRED = 'TRIAL_EXPIRED';

const initializeProject = (projects, projectMap, pk) => {
  if (projects[pk]) return;
  projects[pk] = {
    pk: pk,
    name: projectMap[pk].name,
    entries: {},
  };
};

const groupByProject = (entries, projectMap) => {
  const projects = {};
  if (Object.keys(entries).length > 0) {
    Object.keys(entries).forEach((key) => {
      const entry = entries[key];
      initializeProject(projects, projectMap, entry.project);
      projects[entry.project].entries[entry.pk] = entry;
    });
  } else {
    Object.keys(projectMap).forEach((key) => {
      initializeProject(projects, projectMap, key);
    });
  }
  return projects;
};

const projectsWithEntries = (entries) => {
  const projects = {};
  Object.keys(entries).forEach((key) => {
    projects[entries[key].project] = true;
  });
  return projects;
};

export const Day = (props) => {
  if (props.skeleton) {
    return <Skeleton />;
  };

  const dispatch = useContext(GlobalDispatchContext);
  const state = useContext(GlobalStateContext);
  const date = moment.utc(props.date);

  const [projects, setProjects] = useState({});

  useEffect(() => {
    if (props.data.entries && state.projectMap) {
      setProjects(groupByProject(props.data.entries, state.projectMap));
    }
  }, [props.data.entries, props.projectMap]);

  // TODO: move to a new shared location (probably entry.js)
  const handleAddEntry = (projectId) => {
    api().post(`/api/entries/`, {
      project_id: projectId,
      date: props.date,
      content: '',
    }).then((response) => {
      const actionType = props.projectView
        ? 'ADD_PROJECT_DETAIL_ENTRY' : 'ADD_ENTRY';
      dispatch({
        type: actionType,
        entry: {
          ...response.data,
          focused: true,
        },
      });
    }).catch((e) => {
      const error = e.response.data;
      if (error.code === TRIAL_EXPIRED) {
        setSessionData('trial_expired', true);
        alert(error.message);
        // HACK: reload so that the layout banner reads from localStorage
        if (browser()) {
          window.location = window.location;
        }
      } else {
        console.log(error);
        alert('Failed to add new entry');
      }
    });
  };

  return (
    <div className={styles.day}>
      <Card className={styles.content}>
        <div className={styles.header}>
          <div className={styles.date}>
            <div className={styles.name}>
              {date.format('ddd').toUpperCase()}
            </div>
            <div className={styles.number}>
              {date.format('M/D')}
            </div>
          </div>
        </div>
        {projects && Object.keys(projects).map((projectId) => (
          <Project
            projectView={props.projectView}
            key={`${props.date}-${projectId}`}
            date={props.date}
            projectId={projectId}
            entries={projects[projectId].entries}
            onAddEntry={() => handleAddEntry(projectId)}
            onEntryDeleted={props.onEntryDeleted}
          />
        ))}
        {!props.projectView &&
          <ProjectPicker
            projectsWithEntries={projectsWithEntries(props.data.entries)}
            onAddProject={handleAddEntry}
            onNewProject={props.onNewProject}
            filterActive={props.filterActive}
          />
        }
      </Card>
    </div>
  );
};

const Skeleton = () => {
  return (
    <div className={`${styles.day} ${styles.skeleton}`}>
      <div className={styles.date}>
      </div>
      <Card className={styles.content}>
      </Card>
    </div>
  );
};
